<template>
  <b-card
      class="store-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
    <b-form id="storeForm" class="edit-form mt-2">
      <b-card header="基本信息" >
        <div class="card-body">
          <b-row>
            <!--公司主体-->
            <b-col
                    cols="12"
                    md="3"
                    class="mb-2"
            >
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="公司主体"
                      label-for="mainCompanyName"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="公司主体" #default="{ errors }" >
                <v-select
                        id="mainCompanyName"
                        :options="mainCompanyArr"
                        :clearable="true"
                        class="select-size-sm"
                        v-model="mainCompanyName"
                        @input="setMainCompanyName"
                        placeholder="请选择主体名称"
                        :state="errors.length > 0 ? false:null"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--渠道主体名称-->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="渠道主体名称"
                      label-for="company_name"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="渠道主体名称" #default="{ errors }" >
                <v-select
                        id="company_name"
                        :options="companyArr"
                        :clearable="true"
                        class="select-size-sm"
                        v-model="companyName"
                        @input="setCompanyName"
                        placeholder="请选择主体名称"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--店铺名称-->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺名称"
                      label-for="store_name"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="店铺名称" #default="{ errors }" >
                <b-form-input
                        id="store_name"
                        size="sm"
                        v-model="store.store_name"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--店铺编码-->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺编码"
                      label-for="store_no"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="店铺编码" #default="{ errors }" >
                <b-form-input
                        id="store_no"
                        size="sm"
                        v-model="store.store_no"
                        @blur="isExsit(store)"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <!--店铺ID -->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺ID"
                      label-for="storeid"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required|nonnegativeInteger" name="店铺ID" #default="{ errors }" >
                <b-form-input
                        id="storeid"
                        size="sm"
                        v-model="store.storeid"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--店铺状态 -->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺状态"
                      label-for="store_status"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="店铺状态" #default="{ errors }" >
                <v-select
                        id="store_status"
                        :options="getCodeOptions('store_store_status')"
                        v-model="store_status"
                        @input="setStatus($event,['store_status'])"
                        name="store_status"
                        class="select-size-sm"
                        placeholder="请选择店铺状态"

                />
                <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--店铺链接 -->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺链接"
                      label-for="store_url"
                      label-size="sm"
                      class="mb-1 required"
              >
                <ValidationProvider rules="required" name="店铺链接" #default="{ errors }" >
                <b-form-input
                        id="store_url"
                        size="sm"
                        v-model="store.store_url"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!--所属销售权团队 -->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售权团队"
                  label-for="purchaseTeam"
                  label-size="sm"
                  class="mb-1 required"
              >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="purchase_team_id"
                      @input="setStatus($event,['purchase_team_id'])"
                      name="purchaseTeam"
                      class="select-size-sm"
                      placeholder="请选择所属销售权团队"
                  />
              </b-form-group>
            </b-col>
            <b-col md="3"></b-col>
            <!--附件-->
            <b-col md="12">
              <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="附件"
                      label-for="attachments"
                      label-size="sm"
                      class="mb-1 required"
              >
                <attachment-upload v-if="store.loaded" :theme="'files'"
                                   :attachment_id="'attachments'"
                                   :id="store.attachments"
                                   :object_type="'store'"
                                   :object_id="store.store_id"
                                   @change="onUploadedAtt"
                />
              </b-form-group>
            </b-col>
            <!--备注-->
            <b-col md="12">
              <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="备注"
                      label-for="memo"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-textarea
                        id="remark"
                        rows="3"
                        max-rows="6"
                        style="margin: 5px"
                        v-model="store.memo"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card header="保证金信息" >
        <div class="card-body">
        <b-row>
          <!--后台保证金金额-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="后台保证金金额"
                    label-for="deposit"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="后台保证金金额" #default="{ errors }" >
              <b-form-input
                      id="deposit"
                      size="sm"
                      v-model="store.deposit"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--保证金附件-->
          <b-col md="4">
            <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="保证金附件"
                    label-for="attachments_deposit"
                    label-size="sm"
                    class="mb-1 required"
            >
              <attachment-upload v-if="store.loaded" :theme="'files'"
                                 :attachment_id="'attachments_deposit'"
                                 :id="store.attachments_deposit"
                                 :object_type="'store'"
                                 :object_id="store.store_id"
                                 @change="onUploadedAtt"
              />
            </b-form-group>
          </b-col>
          <!--OA流程编号-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="OA流程编号"
                    label-for="oa_workflow_no"
                    label-size="sm"
                    class="mb-1 "
            >
              <b-form-input
                      id="oa_workflow_no"
                      size="sm"
                      v-model="store.oa_workflow_no"
              />
            </b-form-group>
          </b-col>
        </b-row>
        </div>
      </b-card>

      <b-card header="银行信息" >
        <div class="card-body">
        <b-row>
          <!--账户名称-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="账户名称"
                    label-for="account_id"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="账户名称" #default="{ errors }" >
              <b-form-input
                      id="account_id"
                      size="sm"
                      v-model="accountName"
                      v-b-modal.modal-bank-account
                      placeholder="点击搜索公司账户"
                      readonly
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--收款账号 -->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款账号"
                    label-for="accountNo"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="收款账号" #default="{ errors }" >
              <b-form-input
                      id="accountNo"
                      size="sm"
                      v-model="accountNo"
                      readonly
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--开户行-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="开户行"
                    label-for="deposit_bank"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="开户行" #default="{ errors }" >
              <b-form-input
                      id="deposit_bank"
                      size="sm"
                      v-model="deposit_bank"
                      readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        </div>
      </b-card>

      <b-card header="账户信息" >
        <div class="card-body">
        <b-row>
          <!--店铺账户-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺账户"
                    label-for="user_name"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="店铺账户" #default="{ errors }" >
              <b-form-input
                      id="user_name"
                      size="sm"
                      v-model="store.user_name"
                      placeholder="请输入账户名称(登录账号)"

              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--密码 -->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="密码"
                    label-for="user_pass"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="密码" #default="{ errors }" >
              <b-form-input
                      id="user_pass"
                      size="sm"
                      v-model="store.user_pass"
                      placeholder="请输入账户密码(登录密码)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--登录手机保管人-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="登录手机保管人"
                    label-for="logintel_care"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="登录手机保管人" #default="{ errors }" >
                <modal-select
                        type="input"
                        v-on:change="fromChildren($event,['logintel_care_name','logintel_care'])"
                        :params="['logintel_care_name','logintel_care']"
                        modalName="创建人"
                        placeholder="点击登录手机保管人"
                        v-model="logintel_care_name"
                >
                </modal-select>
              <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        </div>
      </b-card>

      <b-card header="提现信息" >
        <div class="card-body">
        <b-row>
          <!--店铺提现状态-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺提现状态"
                    label-for="get_cash_status"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="店铺提现状态" #default="{ errors }" >
              <v-select
                      id="get_cash_status"
                      :options="getCodeOptions('get_cash_status')"
                      v-model="get_cash_status"
                      name="get_cash_status"
                      @input="setStatus($event,['get_cash_status'])"
                      class="select-size-sm"
                      placeholder="请选择店铺提现状态"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--提现方式 -->
          <b-col md="4">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提现方式"
                    label-for="get_cash_method"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="提现方式" #default="{ errors }" >
              <v-select
                      id="get_cash_method"
                      :options="getCodeOptions('get_cash_method')"
                      v-model="get_cash_method"
                      name="get_cash_method"
                      @input="setStatus($event,['get_cash_method'])"
                      class="select-size-sm"
                      placeholder="请选择提现方式"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--提现频率-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提现频率"
                    label-for="get_cash_rate"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="提现频率" #default="{ errors }" >
              <v-select
                      id="get_cash_rate"
                      :options="getCodeOptions('get_cash_rate')"
                      v-model="get_cash_rate"
                      @input="setStatus($event,['get_cash_rate'])"
                      name="get_cash_rate"
                      class="select-size-sm"
                      placeholder="请选择提现频率"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--提现负责人-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提现负责人"
                    label-for="get_cash_user"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="提现负责人" #default="{ errors }" >
                <modal-select
                        type="input"
                        v-on:change="fromChildren($event,['get_cash_user_name','get_cash_user'])"
                        :params="['get_cash_user_name','get_cash_user']"
                        modalName="创建人"
                        placeholder="点击提现负责人"
                        v-model="get_cash_user_name"

                >
                </modal-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--提现绑定手机号-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提现绑定手机号"
                    label-for="get_cash_usetel"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="提现绑定手机号" #default="{ errors }" >
              <b-form-input
                      id="get_cash_usetel"
                      size="sm"
                      v-model="store.get_cash_usetel"

              />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--提现手机保管人-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="提现手机保管人"
                    label-for="get_cash_usetel_user"
                    label-size="sm"
                    class="mb-1 required"
            >
              <ValidationProvider rules="required" name="提现手机保管人" #default="{ errors }" >
                <modal-select
                        type="input"
                        v-on:change="fromChildren($event,['get_cash_usetel_user_name','get_cash_usetel_user'])"
                        :params="['get_cash_usetel_user_name','get_cash_usetel_user']"
                        modalName="创建人"
                        placeholder="点击提现手机保管人"
                        v-model="get_cash_usetel_user_name"
                >
                </modal-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        </div>
      </b-card>

      <b-card header="其它信息" >
        <div class="card-body">
        <b-row>
          <!--售卖类型-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="售卖类型"
                    label-for="sales_type"
                    label-size="sm"
                    class="mb-1"
            >

              <v-select
                      id="transport_type"
                      :options="getCodeOptions('store_sales_type')"
                      :clearable="true"
                      v-model="sales_type"
                      @input="setStatus($event,['sales_type'])"
                      class="select-size-sm"
                      placeholder="请选择售卖类型"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <!--ERP编码-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="ERP编码"
                    label-for="erp_code"
                    label-size="sm"
                    class="mb-1"
            >
              <b-form-input
                      id="erp_code"
                      size="sm"
                      v-model="store.erp_code"
              />
            </b-form-group>
          </b-col>
          <!--回款主体-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <div v-if="user.role_id ===11 ||user.role_id ===12  ">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="回款主体"
                      label-for="returnmoneycompanyname"
                      label-size="sm"
                      :class="'mb-1 '+ required"
              >
                <ValidationProvider :rules="required" name="回款主体" #default="{ errors }" >
                  <v-select
                          id="returnmoneycompanyname"
                          :options="mainCompanyArr"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="returnCompanyName"
                          @input="setReturnCompanyName"
                          placeholder="请选择回款主体名称"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </div>

            <div v-else>
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="回款主体"
                      label-for="returnmoneycompanyname_else"
                      label-size="sm"
                      :class="'mb-1 '+ required"
              >
                <b-form-input
                        id="returnCompanyName"
                        size="sm"
                        v-model="returnCompanyName"
                        readonly
                />

              </b-form-group>
            </div>

          </b-col>
          <!--OA开票申请人-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="OA开票申请人"
                    label-for="get_cash_usetel_user"
                    label-size="sm"
                    :class="'mb-1 '+ required"
            >
            <ValidationProvider :rules="required" name="OA开票申请人" #default="{ errors }" >
              <modal-select
                      type="input"
                      v-on:change="fromChildren($event,['invoice_oa_fullname','invoice_oa_userid'])"
                      :params="['invoice_oa_fullname','invoice_oa_userid']"
                      modalName="创建人"
                      placeholder="点击选择OA开票申请人"
                      v-model="invoice_oa_fullname"
                >
              </modal-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </b-form-group>
          </b-col>
          <!--品牌团队-->
          <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
          >
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌团队"
                    label-for="store_team"
                    label-size="sm"
                    :class="'mb-1 '+ required"
            >
              <ValidationProvider :rules="required" name="品牌团队" #default="{ errors }" >
              <v-select
                      id="store_team"
                      :options="getCodeOptions('store_team')"
                      :clearable="true"
                      v-model="store_team"
                      @input="setStatus($event,['store_team'])"
                      class="select-size-sm"
                      placeholder="请选择品牌团队"
              >
              </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        </div>
        <div class="card-footer text-right ">
          <b-col
                  cols="12"
                  class="mt-50"
          >
            <b-button
                    variant="primary"
                    class="mr-1"
                    @click="save(1)"
            >
              保存
            </b-button>
            <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    @click="cancel"
            >
              取消
            </b-button>

            <b-button
                    v-if="store.status===2||store.status===5||store.status===undefined"
                    variant="outline-secondary"
                    @click="save(2)"
            >
              提交
            </b-button>

          </b-col>
        </div>
      </b-card>

    </b-form>
    </ValidationObserver>
    <!--/ form -->
    <b-modal
        id="modal-bank-account"
        ok-only
        ok-title="确认"
        @ok="onSelectBank"
        cancel-title="取消"
        centered
        size="lg"
        title="选择公司账户"
    >
      <bank-account-list
          ref="myBankSelect" :companyId="store.companyid"
      >
      </bank-account-list>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import storeStore from './storeStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import axios from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import BankAccountList from "@/views/apps/bankaccount/bankaccount-modal/BankAccountList";
import bankaccountStore from "@/views/apps/bankaccount/bankaccountStore";
import ModalSelect from "@/views/components/modal/ModalSelect";
import {getUserData} from "@/auth/utils";


export default {
  components: {
    BankAccountList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    ModalSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      store: ref({}),
      companyArr: [],
      companyName: {},
      mainCompanyArr: [],
      mainCompanyName: {},
      returnCompanyName: {},
      accountName: '',
      accountNo: '',
      companyIdYang: undefined,
      storeNameYang: undefined,
      required: '',
      invoice_oa_fullname:'',
      get_cash_usetel_user_name:'',
      get_cash_user_name:'',
      logintel_care_name:'',
      deposit_bank:'',
      sales_type:'',
      store_team:'',
      get_cash_method:'',
      get_cash_status:'',
      get_cash_rate:'',
      store_status:'',
      user:{},
      purchase_team_id:{},
    }
  },
  methods: {
    setCompanyName() {
      this.store.company_name = this.companyName.label
      this.store.company_id = this.companyName.company_id
      if(this.store.company_id == 90){
        //线下GMV
        this.required="required"
      }else{
        this.required=''
      }
    },
    setMainCompanyName() {
      this.store.companyname = this.mainCompanyName.label
      this.store.companyid = this.mainCompanyName.company_id
      this.store.account_id = ''
      this.accountName = ''
      this.accountNo = ''
      this.deposit_bank = ''
      axios.post('/api/bankaccount/search', {
        company_id: this.store.companyid
      }).then(res => {
        if (res.data.data.list.length == 1) {
          this.store.account_id = res.data.data.list[0].account_id
          this.store.deposit_bank = res.data.data.list[0].bank_name
          this.accountName = res.data.data.list[0].account_name
          this.accountNo = res.data.data.list[0].account_no
          this.deposit_bank = res.data.data.list[0].bank_name
        }
      })
      this.$forceUpdate()
    },
    setReturnCompanyName() {
      this.store.returnmoney_companyname = this.returnCompanyName === null ? null : this.returnCompanyName.label
      this.store.returnmoney_companyid = this.returnCompanyName === null ? "" : this.returnCompanyName.company_id
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('bankaccount')) store.registerModule('bankaccount', bankaccountStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('bankaccount')) store.unregisterModule('bankaccount')
    })

    const edit = function () {
      store.dispatch('store/edit', {id: this.id}).then(res => {
        this.store = res.data.data
        this.companyIdYang = this.store.companyid
        this.storeNameYang = this.store.store_name
        getCompany(this.companyArr)
        getMainCompany(this.mainCompanyArr)
        store.dispatch('bankaccount/searchById', {
          id: this.store.account_id
        })
            .then(res => {
              this.accountName = res.data.data == null ? '' : res.data.data.account_name
              this.accountNo = res.data.data == null ? '' : res.data.data.account_no
              this.deposit_bank = res.data.data == null ? '' : res.data.data.bank_name
            })
        this.companyName = this.store.company_name
        this.mainCompanyName = this.store.companyname
        this.returnCompanyName = this.store.returnmoney_companyname
        if (this.store.sales_type != undefined) {
          this.sales_type = getCode("store_sales_type", this.store.sales_type)
        }

        if (!isEmpty(this.store.invoice_oa_userid)) {
          this.invoice_oa_fullname =  getCodeLabel('user', this.store.invoice_oa_userid)
        }
        if (!isEmpty(this.store.logintel_care)) {
          this.logintel_care_name =  getCodeLabel('user', this.store.logintel_care)
        }

        if (!isEmpty(this.store.get_cash_usetel_user)) {
          this.get_cash_usetel_user_name =  getCodeLabel('user', this.store.get_cash_usetel_user)
        }

        if (!isEmpty(this.store.get_cash_user)) {
          this.get_cash_user_name =  getCodeLabel('user', this.store.get_cash_user)
        }

        if (!isEmpty(this.store.store_team)) {
          this.store_team = getCode("store_team", this.store.store_team)
        }
        if (!isEmpty(this.store.get_cash_method)){
          this.get_cash_method = getCode("get_cash_method", this.store.get_cash_method)
        }
        if (!isEmpty(this.store.get_cash_status)){
          this.get_cash_status = getCode("get_cash_status", this.store.get_cash_status)
        }

        if (!isEmpty(this.store.get_cash_rate)){
          this.get_cash_rate = getCode("get_cash_rate", this.store.get_cash_rate)
        }
        if (!isEmpty(this.store.store_status)){
          this.store_status = getCode("store_store_status", this.store.store_status)
        }
        if (!isEmpty(this.store.purchase_team_id)){
          this.purchase_team_id = getCode("purchase_team", this.store.purchase_team_id)
        }
        if(this.store.company_id == 90){
          //线下GMV
          this.required="required"
        }else{
          this.required=''
        }
      })
    }

    const view = function () {
      store.dispatch('store/view', {id: this.id}).then(res => {
        this.store = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }


    const save = async function (result) {
      let success = await this.$refs.observer.validate()
      if (isEmpty(this.store.purchase_team_id)){
        toast.error("请选择销售权团队")
        return false
      }
      if (this.store.attachments_deposit === undefined || this.store.attachments_deposit === "") {
        toast.error("请上传附件!")
        return false
      }
      if (this.store.attachments === undefined || this.store.attachments === "") {
        toast.error("请上传附件!")
        return false
      }
      let res = await store.dispatch('store/judgeStoreCode', this.store)
      if (res.data.code === 1) {
        toast.error(res.data.data)
        return false
      }
      if(success){
        if (this.store.companyid !== this.companyIdYang || this.store.store_name !== this.storeNameYang) {
          if (confirm("检测到公司主体、商铺名称变更，保存后需要财务审核？")) {
            store.dispatch('store/save', this.store).then(res => {
              if (res.data.code === 0) {
                this.store = res.data.data
                if (result === 2) {
                  let status = 3
                  let state = 0
                  store.dispatch('store/submit', {id: this.store.store_id, state: state, status: status}).then(res => {
                    if (res.data.code === 0) {
                      toast.success("操作成功")
                    }
                  })
                }
                this.$router.push({name: 'apps-store-list'})
              } else {
                toast.error(res.data.data)
              }
            })
          }
        } else {
          store.dispatch('store/save', this.store).then(res => {
            if (res.data.code === 0) {
              this.store = res.data.data
              if (result === 2) {
                let status = 3
                let state = 0
                store.dispatch('store/submit', {id: this.store.store_id, state: state, status: status}).then(res => {
                  if (res.data.code === 0) {
                    toast.success("操作成功")
                  }
                })
              }
              this.$router.push({name: 'apps-store-list'})
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }else {
        toast.error('请填写缺失字段')
      }
    }

    const getCompany = function (arr) {
      axios.get('/api/company/list', {
        params: {
          company_type: 1
        }
      }).then(function (res) {
        const a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          arr.push({"label": a[i].company_name, "company_id": a[i].company_id})
        }
      }).catch(function (res) {
        alert(res)
      })
    }

    const getMainCompany = function (arr) {
      axios.get('/api/company/list', {
        params: {
          companyTypes: '2'
        }
      }).then(function (res) {
        const a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          arr.push({"label": a[i].company_name, "company_id": a[i].company_id})
        }
      }).catch(function (res) {
        alert(res)
      })
    }


    const onUploadedAtt = function (id, attachment, result) {
      this.store[id] = result
    }

    const isExsit = function (v) {
      axios.get('/api/store/judgeStoreCode', {
        params: v
      }).then(function (res) {
        if (res.data.code === 1) {
          toast.error(res.data.data)
        }
        return
      })
    }

    const onSelectBank = function () {
      let checked = this.$refs.myBankSelect.getSelected()[0];
      this.accountName = checked.account_name
      this.accountNo = checked.account_no
      this.store.account_id = checked.account_id
      this.store.deposit_bank = checked.bank_name
      this.deposit_bank = checked.bank_name
    }

    const fromChildren = function (params, modal) {
      let name = modal[0]
      let value = params[modal[0]]
      if(name==='invoice_oa_fullname'){
        this.invoice_oa_fullname=value
      }else if (name==='get_cash_usetel_user_name'){
        this.get_cash_usetel_user_name=value
      }else if (name==='get_cash_user_name'){
        this.get_cash_user_name=value
      }else{
        this.logintel_care_name=value
      }
      for (let i = 0; i < modal.length; i++) {
        this.store[modal[i]] = params == null ? 0 : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const setStatus = function (result,params) {
      let name = params[0]
       let value =""
      let label =""
      if(!isEmpty(result)){
        value = result.value
        label = result.label
      }
      if(name==='sales_type'){
        this.store.sales_type=value
        this.sales_type=label
      }else if (name==='store_team'){
        this.store.store_team=value
        this.store_team=label
      }else if (name==='get_cash_method'){
        this.store.get_cash_method=value
        this.get_cash_method=label
      }else if (name==='get_cash_status'){
        this.store.get_cash_status=value
        this.get_cash_status=label
      }else if (name==='get_cash_rate') {
        this.store.get_cash_rate = value
        this.get_cash_rate = label
      }else if (name==='store_status') {
        this.store.store_status = value
        this.store_status = label
      }else if (name=== 'purchase_team_id') {
        this.store.purchase_team_id = value
      }
      this.$forceUpdate()
    }


    return {
      edit,
      view,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      getCompany,
      getMainCompany,
      onUploadedAtt,
      isExsit,
      onSelectBank,
      fromChildren,
      setStatus
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    console.log(1,userData)
    this.id = this.$route.query.id || 0;
    this.edit()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
